import { Directive, ElementRef, Renderer2, HostListener } from "@angular/core";

@Directive({
  selector: '[appStyleFilter]',
  standalone: true
})
export class StyleFilterDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const filter = this.el.nativeElement;
    const header = document.querySelector('.l-header-container');
    if (!(filter && header)) return;
    const filterRect = filter.getBoundingClientRect();
    const headerRect = header.getBoundingClientRect();
    let top = Math.floor(filterRect.top);
    let height = Math.ceil(headerRect.height);

    if (top < (height + 5)) {
      this.renderer.setStyle(filter, 'top', `${height + 5}px`);
      this.renderer.setStyle(filter, 'position', 'sticky');
    }
  }
}
