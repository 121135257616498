<div class="filter-chips-parent-container" *ngIf="filterChips.length">
  <div class="search-result-header">
    <div class="d-flex gap-2 align-items-center">
      <span  class="filter-chip-your-selection">Your selections</span>
      <span  class="clear-all-btn" (click)="clearAllFilters()">Clear all filters</span>
    </div>
  </div>

  <div class="chip-wrapper">
    <div *ngFor="let chip of filterChips; let i = index" class="filter-chip" [attr.data-testchip]="chip.label">
      <span class="filter-text-format">{{chip.label}}</span>
      <ng-template [ngTemplateOutlet]="cross" [ngTemplateOutletContext]="{index:i}"></ng-template>
    </div>
  </div>
</div>

<ng-template #cross let-i="index">
  <svg (click)="removeFilterByChipId(i);updateView()" xmlns="http://www.w3.org/2000/svg" width="10" height="10"
    viewBox="0 0 10 10" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M1.44309 1.44602C1.56513 1.32398 1.76299 1.32398 1.88503 1.44602L4.9974 4.55838L8.10976 1.44602C8.2318 1.32398 8.42966 1.32398 8.5517 1.44602C8.67374 1.56806 8.67374 1.76592 8.5517 1.88796L5.43934 5.00033L8.5517 8.11269C8.67374 8.23473 8.67374 8.43259 8.5517 8.55463C8.42966 8.67667 8.2318 8.67667 8.10976 8.55463L4.9974 5.44227L1.88503 8.55463C1.76299 8.67667 1.56513 8.67667 1.44309 8.55463C1.32105 8.43259 1.32105 8.23473 1.44309 8.11269L4.55545 5.00033L1.44309 1.88796C1.32105 1.76592 1.32105 1.56806 1.44309 1.44602Z"
      fill="#222222" />
  </svg>
</ng-template>
