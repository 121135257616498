import { HttpContextToken } from "@angular/common/http";
import { CollectionPage } from "../models/collectionResult";
import { SortBy } from "../models/sortBy";

export const collectionResultPage:CollectionPage =
{
  highestcashback: {
    route: 'HighestCashback',
    bannerImg: '../../../../assets/images/collections/Highest cashbacl.svg',
    bannerImgLeft: '../../../../assets/images/collections/left-Highest cashbacl.svg',
    bannerHeading1:'Shop our highest',
    bannerHeading2:'cashback items',
    bannerBgColor:'#E7F8DE',
    resultHeader:'Trending Items'
  },
  bestseller: {
    route: 'Bestsellers',
    bannerImg: '../../../../assets/images/collections/Bestsellers.svg',
    bannerImgLeft: '../../../../assets/images/collections/left-Bestsellers.svg',
    bannerHeading1:'Shop our',
    bannerHeading2:'bestsellers',
    bannerBgColor:'#EFE6D8',
    resultHeader:'Trending Items'
  },
  travelessentials: {
    route: 'Travel-essentials',
    bannerImg: '../../../../assets/images/collections/Travel.svg',
    bannerImgLeft: '../../../../assets/images/collections/left-Travel.svg',
    bannerHeading1:'Stock up on travel',
    bannerHeading2:'essentials',
    bannerBgColor:'#AED5E9',
    resultHeader:'Trending Items'
  }
}

export const SearchCacheKey = 'shopSearch'

export const filterOptionsToShow = 20;

export const collections = [
  { heading: 'Stock up on travel essentials', link: '/shop', img: '../../../../assets/images/collections.png', color: '#ADD3E9' },
  { heading: 'Build out your dream kitchen', link: '/shop', img: '../../../../assets/images/collection.png', color: '#E7E6E7' },
  { heading: 'Finds from the great outdoors', link: '/shop', img: '../../../../assets/images/collections.png', color: '#E6D8C9' },]

export const Categories = [
  { "label": "Sports & Outdoors", "image": "/assets/images/category-logo/TravelAndOutDoorsIconSquare.png" },
  { "label": "Home & Kitchen", "image": "/assets/images/category-logo/HomeAndKitchenIconSquare.png" },
  { "label": "Health & Beauty", "image": "/assets/images/category-logo/HealthAndBeautyIconSquare.png" },
  { "label": "Clothing", "image": "/assets/images/category-logo/ClothingIconSquare.png" },
  { "label": "Shoes", "image": "/assets/images/category-logo/ShoesIconSquare.png" },
  { "label": "Accessories", "image": "/assets/images/category-logo/AccessoriesIconSquare.png" },
  { "label": "Toys, Kids, & Baby", "image": "/assets/images/category-logo/ToysKidsAndBabyIconSquare.png" },
  { "label": "Office & Professional", "image": "/assets/images/category-logo/OfficeAndProfessionalIconSquare.png" },
  { "label": "Electronics & Devices", "image": "/assets/images/category-logo/ElectronicsAndDevicesIconSquare.png" },
  { "label": "Games & Entertainment", "image": "/assets/images/category-logo/GamesAndEntertainmentIconSquare.png" },
  { "label": "Pet Supplies", "image": "/assets/images/category-logo/PetSuppliesIconSquare.png" },
  { "label": "Seasonal & Party Supplies", "image": "/assets/images/category-logo/SeasonalAndPartySuppliesIconSquare.png" },
  { "label": "Grocery", "image": "/assets/images/category-logo/GroceryIconSquare.png" }
]

export const searchCategories =
  [
    {
      label: 'Sports & Outdoors',
      // items: [
      //   {
      //     label: 'All Travel & Outdoors',
      //   },
      //   {
      //     label: 'Outdoor Recreation',
      //   },
      //   {
      //     label: 'Luggage & Travel Accessories',
      //   },
      //   {
      //     label: 'Camping & Hiking',
      //   },
      //   {
      //     label: 'Outdoor Clothing',
      //   },
      //   {
      //     label: 'Outdoor Cooking',
      //   },
      //   {
      //     label: 'Tools',
      //   },
      //   {
      //     label: 'Outdoor Electronics',
      //   },
      //   {
      //     label: 'Experiences',
      //   }
      // ]
    },
    {
      label: 'Home & Kitchen',
      items: []
    },
    {
      label: 'Health & Beauty',
      items: []
    },
    {
      label: 'Clothing',
      // items: [
      //   {
      //     label: 'All Clothing, Shoes, & Accessories',
      //     items: []
      //   },
      //   {
      //     label: `Men's Clothing`,
      //     items: [
      //       {
      //         label: `All Men's clothing`,
      //       },
      //       {
      //         label: 'Tops',
      //       },
      //       {
      //         label: 'Bottoms',
      //       },
      //       {
      //         label: 'Outerwear',
      //       },
      //       {
      //         label: 'Suits & Formal Wear',
      //       },
      //       {
      //         label: 'Swimwear',
      //       },
      //       {
      //         label: 'Socks & Underwear',
      //       },
      //     ]
      //   },
      //   {
      //     label: `Women's Clothing`,
      //     items: []
      //   },
      //   {
      //     label: 'Shoes & Footwear',
      //     items: []
      //   },
      //   {
      //     label: 'Accessories',
      //     items: []
      //   },
      //   {
      //     label: 'Bags',
      //     items: []
      //   },
      //   {
      //     label: 'Activewear',
      //     items: []
      //   }
      // ]
    },
    {
      label: 'Shoes',
      items: []
    },
    {
      label: 'Accessories',
      items: []
    },
    {
      label: 'Toys, Kids, & Baby',
      items: []
    },
    {
      label: 'Electronics & Devices',
      items: []
    },
    {
      label: 'Games & Entertainment',
      items: []
    },
    {
      label: 'Pet Supplies',
      items: []
    },
    {
      label: 'Grocery',
      items: []
    },
]

export const retailerList = [
  {
    label: 'Walmart',
    image: '/assets/logo/ball.png'
  },
  {
    label: 'Etsy',
    image: '/assets/logo/etsy-logo.svg'
  },
  {
    label: 'Ebay',
    image: '/assets/logo/ebay-logo.svg'
  },
  {
    label: 'Amazon',
    image: '/assets/logo/amazon-logo.svg'
  },
  {
    label: 'Costco',
    image: '/assets/logo/costco-logo.svg'
  },
  {
    label: 'Target',
    image: '/assets/logo/target-logo.svg'
  },
  {
    label: 'Bass Pro Shops',
    image: '/assets/logo/bass-pro-logo.svg'
  },
  {
    label: 'Cabela’s',
    image: '/assets/logo/cabela-logo.svg'
  }
]

export const BannerDetails = [{
  text_underlined: 'Payday is here',
  prefix_text_underlined: '',
  post_text_underlined: 'Browse, Buy, Collect, Cash Out.',
  image_url: '.../../../../assets/SKUCardsImage.png',
  isTextUnderlined: true,
  isEllipse: true,
  isSparkle: true,

}]

export const searchDefaultSort  = {
    label: 'Relevance',
    value: 0
  }
export class SearchConst {

  static type = {
    Brands: 'Brands',
    Merchants: 'Merchants',
    Categories: 'Categories'
  }

  static options = [
    { label: 'APple', isSelected: false },
    { label: 'APple2', isSelected: false },
    { label: 'APple3', isSelected: false },
    { label: 'APple4', isSelected: false },
    { label: 'APple5', isSelected: false },
  ]

  static filterItems = [
    {
      label: 'Brands',
      options: JSON.parse(JSON.stringify(this.options)),
      isOpen: false,
      showClear: false,
      multiSelect: true
    },
    {
      label: 'Category',
      options: JSON.parse(JSON.stringify(this.options)),
      isOpen: false,
      showClear: false,
      multiSelect: true
    },
    {
      label: 'Cashback',
      options: JSON.parse(JSON.stringify(this.options)),
      isOpen: false,
      showClear: false,
      multiSelect: false,
      selectedValue: ''
    },
    {
      label: 'Size',
      options: JSON.parse(JSON.stringify(this.options)),
      isOpen: false,
      showClear: false,
      multiSelect: true
    }
  ]

  static CategoryItemDict:SortBy[] = [
    { sortKey: 'Highest percent cashback', itemKey: 'percentageCashback', reverse: true, id:1},
    { sortKey: 'Relevance', itemKey: 'relevance', reverse: false, id:0 },
    { sortKey: 'Highest total cashback', itemKey: 'cashback', reverse: true, id:2 },
    { sortKey: 'Price low to high', itemKey: 'price', reverse: false, id:4 },
    { sortKey: 'Price high to low', itemKey: 'price', reverse: true, id:5 },
  ]
}
export class CognitoResponseCode {
  static UserNotConfirmedException = 'UserNotConfirmedException';
  static NotAuthorizedException = 'NotAuthorizedException';
  static UsernameExistsException = 'UsernameExistsException';
  static CodeDeliveryFailureException = 'CodeDeliveryFailureException';
  static InvalidPasswordException = 'InvalidPasswordException';
  static CodeMismatchException = 'CodeMismatchException';
  static Success = 'SUCCESS';
}


export const skeleton_data_landing=[{
  isCard:false,
  bannerTitle:'Recommended Shopping',
  skus: Array(5).fill({sku:{
    imageUrl: '',
    manufacturer: '',
    merchant: '',
    name: '',
    cashbackAmount: 0,
    cashbackPercent: 0,
    salePrice: '',
    purchaseUrl: '',
    sourceCampaignId: '',
    id: '',
    addedDate: '',
    sourceName: '',
    }})
},
{
  isCard:false,
  bannerTitle:'',
  skus: Array(5).fill({sku:{
    imageUrl: '',
    manufacturer: '',
    merchant: '',
    name: '',
    cashbackAmount: 0,
    cashbackPercent: 0,
    salePrice: '',
    purchaseUrl: '',
    sourceCampaignId: '',
    id: '',
    addedDate: '',
    sourceName: '',
    }})
},
{
  isCard:false,
  bannerTitle:'',
  skus: Array(5).fill({sku:{
    imageUrl: '',
    manufacturer: '',
    merchant: '',
    name: '',
    cashbackAmount: 0,
    cashbackPercent: 0,
    salePrice: '',
    purchaseUrl: '',
    sourceCampaignId: '',
    id: '',
    addedDate: '',
    sourceName: '',
    }})
}
];

export const collectionType = {
  system: "System",
  user: "UserPreferenced",
  nonUser: "Non User"
}

export const CategoryComp = {
  mobileHeader: 'Categories',
  maxDisplayLevel: 3,
  shopAll : 'Shop All',
  dropdownItemType:{
    heading: 'heading',
    leaf: 'leaf'
  }
}

export const categoryResultPage = {
  route: 'category-result',
  queryParams: ['Categories', 'CategoriesLevel2', 'CategoriesLevel3', 'CategoriesLevel4'],

}
