import { Directive, OnInit, ElementRef, Renderer2 } from "@angular/core";

@Directive({
    selector: '[appStylePagination]',
    standalone: true
  })
  export class StylePaginationDirective implements OnInit {

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnInit(): void {
      const dummyFilterdiv = this.el.nativeElement.querySelector('.dummy-filter-pagination') as HTMLElement;
      const filterSection = this.el.nativeElement.querySelector('.filters-section') as HTMLElement;
      if (dummyFilterdiv && filterSection) {
        this.renderer.setStyle(dummyFilterdiv, 'width', filterSection.getBoundingClientRect().width + 'px');
      }
    }
  }
