<div class="filters-section">
  <div class="filter-group-container" *appDeviceType="'desktop'">
    <ng-template [ngTemplateOutlet]="GoogleCategory" *ngFor="let filterObj of filterItemsGoogleCategory"
      [ngTemplateOutletContext]="{filterObj:filterObj,isMobile:false}"></ng-template>

    <ng-template [ngTemplateOutlet]="filter" *ngFor="let filterObj of filterItems"
      [ngTemplateOutletContext]="{filterObj:filterObj,isMobile:false}"></ng-template>
  </div>
</div>


<ng-template #filter let-filterObj="filterObj" let-isMobile="isMobile">
  <div class="filter-group" [ngClass]="{'filter-group-height': filterObj.isSelected}" *ngIf="(filterObj.options.length > 0 && filterObj.label !== 'Price') || (filterObj.label === 'Price' && showPriceFilter)">
    <div class="filter-selection-container" (click)="toggleFilterOptions( filterObj,filterItems)">
      <div class="filter-selection">{{filterObj.label}}</div>
      <div class="filter-count">
        <div class="count" *ngIf="getSelectedFilterCount(filterObj.label)>0">{{getSelectedFilterCount(filterObj.label)}}</div>
        <img class="downarrow-alignment" src="../../../assets/icons/downarrow.svg" alt="">
      </div>
    </div>
    <div *ngIf="filterObj.isSelected && filterObj.label === 'Brands'" class="search-container">
      <img src="../../../../assets/icons/search.svg" alt="Search Icon" class="search-icon" />
      <input type="text" [(ngModel)]="filterObj.searchText" (focus)="focus($event)" placeholder="Search a brand" class="search-input">
      <span class="clear-icon" *ngIf="filterObj.searchText" (click)="clearSearch(filterObj)">x</span>
    </div>

    <ng-container *ngIf="filterObj.isOpen && filterObj.multiSelect">
      <div class="filter-group-height">
        <div class="filter-options" *ngFor="let option of filterObj | searchFilter: filterObj.searchText | seeMoreOptions : filterObj.noOfOptionsToShow">
          <p-checkbox [(ngModel)]="option.isSelected" [binary]="true" (onChange)="
              onFilterCheckboxClick( option.label,filterObj.label,option.isSelected,isMobile);
              updateRequestFilter(filterObj.label, option.label,option.isSelected)
            ">
          </p-checkbox>
          <p>{{option.label}} <span *ngIf="filterObj.label === 'Brands'">{{ '(' + option.count + ')'}}</span></p>
        </div>
        <div class="see-more" *ngIf="showSeeMore(filterObj)" (click)="seeMoreOptions(filterObj)">See more</div>
    </div>
    </ng-container>
    <ng-container *ngIf="filterObj.isOpen && !filterObj.multiSelect">
      <div class="filter-group-height">
        <div class="filter-options" *ngFor="let item of filterObj | searchFilter: filterObj.searchText | seeMoreOptions: filterObj.noOfOptionsToShow">
          <p-radioButton [name]="filterObj.label" [value]="item.label" [(ngModel)]="filterObj.selectedValue" [inputId]="item.label"></p-radioButton>
            <label [for]="item.label" class="ml-2">{{item.label}}</label>
        </div>
      <div class="see-more" *ngIf="showSeeMore(filterObj)" (click)="seeMoreOptions(filterObj)">See more</div>
    </div>
    </ng-container>
    <ng-container *ngIf="filterObj.isOpen && filterObj.label === 'Price'">
      <div class="filter-price-slider">
        <p-slider [(ngModel)]="priceRange" [range]="true" [min]="minPrice" [max]="maxPrice"
          (onSlideEnd)="onPriceChange($event,!isMobile)"></p-slider>
      </div>
      <div class="d-flex justify-content-between">
        <div class="price-box">
          <span [ngClass]="{black: priceRange[0]!==minPrice}">$</span>
          <input type="number" [min]="minPrice" [max]="maxPrice" [ngModel]="priceRange[0]"
            (ngModelChange)="triggerPriceRange$($event,'min',isMobile)" [ngClass]="{black: priceRange[0]!==minPrice}" />
        </div>
        <div class="price-box">
          <span [ngClass]="{black: priceRange[1]!==maxPrice}">$</span>
          <input type="number" [min]="minPrice" [max]="maxPrice" [ngModel]="priceRange[1]"
            (ngModelChange)="triggerPriceRange$($event,'max',isMobile)" [ngClass]="{black: priceRange[1]!==maxPrice}" />
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>


<ng-template #GoogleCategory let-filterObj="filterObj" let-isMobile="isMobile">
  <div class="filter-group" [ngClass]="{'filter-group-height': filterObj.isSelected}" *ngIf="filterObj.options.length > 0">
    <div class="filter-selection-container" (click)="toggleFilterOptions( filterObj, filterItemsGoogleCategory)">
      <div class="filter-selection">{{secondHighestLevelCategory}}</div>
      <div class="filter-count">
        <div class="count" *ngIf="getSelectedFilterCount(filterObj.label)>0">{{getSelectedFilterCount(filterObj.label)}}</div>
        <img class="downarrow-alignment" src="../../../assets/icons/downarrow.svg" alt="">
      </div>
    </div>

    <ng-container *ngIf="filterObj.isOpen && filterObj.multiSelect">
      <div class="filter-options" *ngFor="let option of filterObj | searchFilter: filterObj.searchText | seeMoreOptions: filterObj.noOfOptionsToShow">
        <p-checkbox [(ngModel)]="option.isSelected" [binary]="true" (onChange)="
            onFilterCheckboxClick( option.label,filterObj.label,option.isSelected,isMobile);
            updateRequestFilter(filterObj.label, option.label,option.isSelected)
          ">
        </p-checkbox>
        <p>{{option.label}} <span>{{ '(' + option.count + ')'}}</span></p>
      </div>
      <div class="see-more" *ngIf="showSeeMore(filterObj)" (click)="seeMoreOptions(filterObj)">See more {{filterObj.options.length}} {{filterObj.noOfOptionsToShow}}</div>
    </ng-container>
    <ng-container *ngIf="filterObj.isOpen && !filterObj.multiSelect">
      <div class="filter-options" *ngFor="let item of filterObj | searchFilter: filterObj.searchText | seeMoreOptions: filterObj.noOfOptionsToShow" (click)="onFilterCategoryClick(item)">
        {{item.label}}
      </div>
      <div class="see-more" *ngIf="showSeeMore(filterObj)" (click)="seeMoreOptions(filterObj)">See more</div>
    </ng-container>

  </div>
</ng-template>


<!-- mobile view -->

<div class="mobile-filter">
  <p-sidebar [(visible)]="sidebarVisible" position="bottom">
    <ng-template pTemplate="header">
      <div class="mobile-header">Filters</div>
    </ng-template>

    <ng-template [ngTemplateOutlet]="GoogleCategory" *ngFor="let filterObj of filterItemsGoogleCategory"
      [ngTemplateOutletContext]="{filterObj:filterObj, isMobile:true}"></ng-template>

    <ng-template [ngTemplateOutlet]="filter" *ngFor="let filterObj of filterItems"
      [ngTemplateOutletContext]="{filterObj:filterObj, isMobile:true}"></ng-template>

    <ng-template pTemplate="footer">
      <div class="clear-all" (click)="clearAllFilters()">Clear all filters</div>
      <p-button class="view-results" (click)="triggerSearchOnMobile()" [disabled]="!showClear">View Results</p-button>
    </ng-template>

  </p-sidebar>
</div>
