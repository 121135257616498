<app-filter-chips *ngIf="searchSection.isGoogleCategorization()"></app-filter-chips>

<div class="empty-display">
  <ng-template [ngTemplateOutlet]="emptyStateImage"></ng-template>
  <b class="specific-note">No search results <span *ngIf="searchQuery">for “{{searchQuery | truncate}}”</span></b>
  <div class="general-note">Try checking your spelling or using different keywords.</div>
</div>

<ng-template #emptyStateImage>
  <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_11439_30857)">
    <rect width="120" height="120" fill="white"/>
    <circle cx="58.5" cy="58.5" r="49.5" fill="var(--l-primary-tenant-color)" fill-opacity="0.15"/>
    <path d="M5.99191 98.8358C5.98067 100.05 6.26713 101.249 6.82623 102.327C7.38533 103.405 8.20003 104.33 9.19912 105.021C10.2535 105.738 11.4988 106.122 12.7739 106.123H44.4878C45.3579 106.121 46.2182 105.94 47.015 105.59C47.8746 105.217 48.6475 104.669 49.2848 103.982C50.5731 102.579 51.2823 100.74 51.2698 98.8358C51.3295 96.9721 50.6501 95.1603 49.3798 93.7952C48.1094 92.4302 46.351 91.6226 44.4878 91.5484H43.1828C43.2377 89.6183 42.5312 87.7441 41.2158 86.3307C39.9003 84.9173 38.0817 84.0781 36.1527 83.9944C34.4105 84.0033 32.7399 84.6886 31.4935 85.9059C30.789 84.0022 29.5278 82.3548 27.874 81.1779C26.2202 80.0011 24.2504 79.3494 22.221 79.3076C16.7071 79.3076 12.1674 84.1414 12.1674 90.1148C12.173 90.6003 12.2068 91.0851 12.2685 91.5667C10.54 91.7199 8.9417 92.5488 7.82066 93.8734C6.63446 95.254 5.98526 97.0157 5.99191 98.8358Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M13.0134 99.6633C13.0087 100.567 13.2098 101.459 13.6016 102.273C14.4131 102.829 15.3749 103.124 16.3585 103.119H40.7665C41.437 103.121 42.1006 102.983 42.7147 102.714C43.2476 101.786 43.5265 100.734 43.5234 99.6633C43.5234 96.7042 41.4649 94.2781 38.9285 94.2781H38.0096C38.0096 91.1995 35.8867 88.6999 33.2768 88.6999C32.6838 88.7068 32.0985 88.8361 31.5577 89.0796C31.017 89.3232 30.5322 89.6757 30.1339 90.1151C29.1047 87.2479 26.697 85.2354 23.8941 85.2354C20.1539 85.2354 17.1121 88.8102 17.1121 93.2212C17.1179 93.5807 17.1425 93.9396 17.1856 94.2964C14.8973 94.4986 13.0134 96.8328 13.0134 99.6633Z" fill="white"/>
    <path d="M113.095 33.1218C113.102 33.9322 112.911 34.7319 112.538 35.4512C112.165 36.1704 111.621 36.7874 110.954 37.248C110.253 37.7271 109.423 37.9834 108.574 37.9832H87.4376C86.8565 37.9705 86.2842 37.8392 85.7558 37.5972C85.1841 37.3476 84.6708 36.9814 84.2487 36.522C83.3837 35.5875 82.9073 34.3585 82.9162 33.0851C82.8789 31.8438 83.3328 30.638 84.1794 29.7295C85.0259 28.821 86.1968 28.2832 87.4376 28.2329H88.3565C88.3118 26.944 88.7802 25.69 89.6588 24.7459C90.5374 23.8019 91.7545 23.2448 93.0433 23.1969C94.2051 23.2043 95.3186 23.6622 96.1494 24.4743C96.6241 23.2238 97.4609 22.1433 98.5527 21.3706C99.6445 20.598 100.942 20.1684 102.279 20.1367C104.122 20.2061 105.862 21.0036 107.118 22.3542C108.374 23.7048 109.043 25.4983 108.978 27.3415C108.976 27.6583 108.951 27.9746 108.905 28.288C110.058 28.3866 111.126 28.9385 111.873 29.8227C112.665 30.7393 113.099 31.9108 113.095 33.1218Z" fill="white" stroke="black" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M108.422 33.6728C108.425 34.2764 108.294 34.8731 108.036 35.4188C107.493 35.7865 106.853 35.9818 106.198 35.9794H89.9317C89.4892 35.9961 89.048 35.921 88.636 35.7588C88.2778 35.1388 88.0907 34.4348 88.0938 33.7187C88.0938 31.7521 89.4631 30.1347 91.1356 30.1347H91.7237C91.7237 28.0854 93.139 26.4588 94.8758 26.4588C95.2692 26.4616 95.6579 26.5445 96.0181 26.7025C96.3783 26.8605 96.7026 27.0903 96.9711 27.3778C97.2475 26.4748 97.7955 25.6789 98.5404 25.0985C99.2853 24.518 100.191 24.1812 101.134 24.1338C103.624 24.1338 105.646 26.5139 105.646 29.4455C105.66 29.6842 105.66 29.9236 105.646 30.1623C106.454 30.3143 107.18 30.7533 107.69 31.3985C108.2 32.0436 108.46 32.8513 108.422 33.6728Z" fill="white"/>
    <path d="M78.6253 80.9049L83.5596 76.0572L73.2937 65.8843L68.3644 70.7244L78.6253 80.9049Z" fill="white" stroke="#212121" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M100.293 102.788L104.985 98.1867C105.423 97.7468 105.669 97.1517 105.67 96.5311C105.671 95.9105 105.427 95.3146 104.99 94.8733L86.0676 75.5266C85.6254 75.0933 85.0311 74.8501 84.412 74.8492C83.7928 74.8482 83.1978 75.0895 82.7542 75.5215L78.0624 80.1223C77.6269 80.5635 77.3822 81.1581 77.3813 81.778C77.3803 82.3979 77.6231 82.9933 78.0572 83.4358L97.0054 102.744C97.4392 103.179 98.0261 103.428 98.6405 103.436C99.2549 103.444 99.8481 103.211 100.293 102.788Z" fill="white"/>
    <path d="M100.293 102.788L104.985 98.1867C105.423 97.7468 105.669 97.1517 105.67 96.5311C105.671 95.9105 105.427 95.3146 104.99 94.8733L86.0676 75.5266C85.6254 75.0933 85.0311 74.8501 84.412 74.8492C83.7928 74.8482 83.1978 75.0895 82.7542 75.5215L78.0624 80.1223C77.6269 80.5635 77.3822 81.1581 77.3813 81.778C77.3803 82.3979 77.6231 82.9933 78.0572 83.4358L97.0054 102.744C97.4392 103.179 98.0261 103.428 98.6405 103.436C99.2549 103.444 99.8481 103.211 100.293 102.788Z" fill="var(--l-primary-tenant-color)"/>
    <path d="M100.293 102.788L104.985 98.1867C105.423 97.7468 105.669 97.1517 105.67 96.5311C105.671 95.9105 105.427 95.3146 104.99 94.8733L86.0676 75.5266C85.6254 75.0933 85.0311 74.8501 84.412 74.8492C83.7928 74.8482 83.1978 75.0895 82.7542 75.5215L78.0624 80.1223C77.6269 80.5635 77.3822 81.1581 77.3813 81.778C77.3803 82.3979 77.6231 82.9933 78.0572 83.4358L97.0054 102.744C97.4392 103.179 98.0261 103.428 98.6405 103.436C99.2549 103.444 99.8481 103.211 100.293 102.788Z" stroke="#212121" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M73.3126 70.8325C77.1369 67.0773 79.7618 62.2724 80.8554 57.0254C81.949 51.7784 81.4622 46.3249 79.4566 41.3545C77.4509 36.3842 74.0165 32.1202 69.5875 29.1016C65.1586 26.0831 59.934 24.4457 54.5744 24.3963C49.2149 24.347 43.9611 25.888 39.4773 28.8245C34.9935 31.7609 31.4812 35.961 29.3843 40.8936C27.2875 45.8262 26.7004 51.2698 27.6972 56.536C28.6941 61.8023 31.2301 66.6547 34.9846 70.4797C37.4779 73.0198 40.4472 75.0439 43.7228 76.4364C46.9985 77.8289 50.5163 78.5624 54.0755 78.5952C57.6347 78.628 61.1655 77.9593 64.4662 76.6273C67.7669 75.2954 70.773 73.3263 73.3126 70.8325ZM39.1378 66.403C36.1901 63.4003 34.1986 59.5911 33.415 55.457C32.6313 51.3228 33.0907 47.0491 34.7351 43.1759C36.3794 39.3027 39.135 36.0038 42.6535 33.6961C46.172 31.3885 50.2957 30.1755 54.5033 30.2105C58.711 30.2456 62.8138 31.527 66.2934 33.893C69.773 36.2589 72.4733 39.6032 74.0529 43.5033C75.6325 47.4033 76.0207 51.6841 75.1683 55.8046C74.316 59.9252 72.2613 63.7006 69.264 66.6539C67.2707 68.6179 64.9097 70.1695 62.3161 71.22C59.7224 72.2705 56.9471 72.7993 54.1489 72.776C51.3507 72.7527 48.5845 72.1778 46.0088 71.0843C43.433 69.9907 41.0981 68.4 39.1378 66.403Z" fill="var(--l-primary-tenant-color)" stroke="#212121" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M68.8762 67.3307C77.6266 59.4517 78.3329 45.9709 70.4539 37.2206C62.5749 28.4703 49.0942 27.764 40.3438 35.643C31.5935 43.522 30.8872 57.0027 38.7662 65.753C46.6452 74.5033 60.1259 75.2097 68.8762 67.3307Z" fill="white"/>
    <path d="M68.8762 67.3307C77.6266 59.4517 78.3329 45.9709 70.4539 37.2206C62.5749 28.4703 49.0942 27.764 40.3438 35.643C31.5935 43.522 30.8872 57.0027 38.7662 65.753C46.6452 74.5033 60.1259 75.2097 68.8762 67.3307Z" fill="var(--l-primary-tenant-color)" fill-opacity="0.15"/>
    <path d="M68.8762 67.3307C77.6266 59.4517 78.3329 45.9709 70.4539 37.2206C62.5749 28.4703 49.0942 27.764 40.3438 35.643C31.5935 43.522 30.8872 57.0027 38.7662 65.753C46.6452 74.5033 60.1259 75.2097 68.8762 67.3307Z" stroke="#212121" stroke-width="2" stroke-miterlimit="10"/>
    <path d="M52.5248 56.0017C58.7725 60.1809 66.2941 59.8959 69.3248 55.3652C72.3555 50.8345 69.7476 43.7737 63.4999 39.5945C57.2523 35.4153 49.7306 35.7002 46.6999 40.231C43.6692 44.7617 46.2771 51.8225 52.5248 56.0017Z" fill="white"/>
    <path d="M30.6599 12.7425C30.7273 12.7561 30.7273 12.8438 30.6599 12.8575C29.8962 13.0124 29.2118 13.4744 28.6863 14.1381C28.1808 14.7842 27.824 15.6175 27.6904 16.5502C27.6813 16.6165 27.5838 16.6165 27.5748 16.5502C27.3035 14.6527 26.1096 13.1625 24.6052 12.8575C24.5379 12.8438 24.5379 12.7561 24.6052 12.7425C25.3689 12.5875 26.0533 12.1265 26.5789 11.4618C27.0854 10.8157 27.4411 9.9824 27.5748 9.0497C27.5838 8.98343 27.6813 8.98343 27.6904 9.0497C27.824 9.9824 28.1798 10.8157 28.6863 11.4618C29.2118 12.1265 29.8962 12.5875 30.6599 12.7425Z" fill="#222222"/>
    <path d="M13.2188 29.1206C12.9145 29.1206 12.6631 28.9071 12.6216 28.6129C12.3931 27.0145 11.7918 25.557 10.884 24.3966C9.95862 23.2272 8.8016 22.4728 7.54074 22.217C7.25513 22.1595 7.05469 21.9229 7.05469 21.6418C7.05469 21.3608 7.25513 21.1251 7.54074 21.0667C8.80264 20.8109 9.95862 20.0564 10.8861 18.885C11.7928 17.7277 12.3941 16.2692 12.6226 14.6697C12.6642 14.3776 12.9145 14.1641 13.2198 14.1641C13.5241 14.1641 13.7755 14.3776 13.817 14.6707C14.0455 16.2702 14.6469 17.7287 15.5546 18.887C16.48 20.0564 17.637 20.8109 18.8978 21.0667C19.1835 21.1241 19.3839 21.3608 19.3839 21.6418C19.3839 21.9229 19.1835 22.1585 18.8968 22.217C16.3304 22.7377 14.2885 25.3082 13.816 28.615C13.7744 28.9071 13.5231 29.1206 13.2188 29.1206ZM13.602 28.481C13.602 28.482 13.602 28.483 13.602 28.484C13.602 28.482 13.602 28.482 13.602 28.481ZM8.9086 21.6428C9.94408 22.084 10.883 22.8213 11.6713 23.8155C12.3484 24.6807 12.8719 25.6899 13.2188 26.7929C13.9811 24.3785 15.5598 22.4879 17.53 21.6438C16.4945 21.2017 15.5546 20.4654 14.7673 19.4712C14.0891 18.607 13.5657 17.5978 13.2188 16.4948C12.8719 17.5967 12.3484 18.605 11.6723 19.4692C10.883 20.4634 9.94304 21.2007 8.9086 21.6428ZM13.602 14.8006C13.602 14.8016 13.601 14.8016 13.601 14.8027C13.602 14.8016 13.602 14.8016 13.602 14.8006Z" fill="#222222"/>
    </g>
    <defs>
    <clipPath id="clip0_11439_30857">
    <rect width="120" height="120" fill="white"/>
    </clipPath>
    </defs>
  </svg>
</ng-template>
