import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchWithFilterService } from 'src/app/shared/services/search/search-with-filter.service';
import { SearchSelectedFilterService } from 'src/app/shared/services/search/search-selected-filter.service';
import { filterActionList } from '../constant';
import { GoogleCategoryResultService } from 'src/app/shared/services/search/google-category-result.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-filter-chips',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './filter-chips.component.html',
  styleUrls: ['./filter-chips.component.scss']
})
export class FilterChipsComponent implements OnInit {

  filterChips: {
    label: string,
    filterName: string
  }[] = [];

  @Input() filterItems: any;
  @Output() action = new EventEmitter();
  subs:any[]=[]

  constructor(
    private ssfService: SearchSelectedFilterService,
    private swfService: SearchWithFilterService,
    private cdRef: ChangeDetectorRef,
    private googleCategoryResultService:GoogleCategoryResultService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.subs = [
      this.ssfService.filterChips$.subscribe((data) => {
        this.filterChips = data;

        // remove featured filter chip
        // currently only for featured brands
        const queryParams = this.route.snapshot.queryParams
        if(!queryParams['isFeatured']) return
        if(queryParams['Brands']){
          this.filterChips = this.filterChips.filter((chip) => chip.label !== queryParams['Brands']);
        }

      }),
      this.googleCategoryResultService.filterItems$.subscribe((data) => {
        this.filterItems = data;
      })
    ]
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe())
  }

  removeFilterChip(filterName: string, optionLabel: string) {
    const filterItems = this.filterItems;
    const category = filterItems.find((filter: any) => filter.label === filterName)
    if (category) {
      const option = category.options.find((opt: any) => opt.label === optionLabel);
      if (option) option.isSelected = false;
      const reqFilter = this.swfService.requestData.filter;
      if(filterName === 'Price'){
        this.ssfService.showPriceChip = false;
        this.googleCategoryResultService.filterChipAction$.next(filterActionList.priceReset);
      }
      else
        this.swfService.requestData.filter[filterName] = reqFilter[filterName]?.filter((item: string) => item !== optionLabel);

    }

    const itemIndex = this.filterChips.findIndex((chip) => chip.filterName === filterName && chip.label === optionLabel)
    this.filterChips = this.filterChips.filter((_, idx) => idx !== itemIndex)
    this.ssfService.updateSelectedFiltersData(this.filterChips)
    this.googleCategoryResultService.filterItems$.next(structuredClone(this.filterItems));
    this.cdRef.detectChanges();
  }

  removeFilterByChipId(i: number) {
    const { filterName, label: optionLabel } = this.filterChips[i];
    this.removeFilterChip(filterName, optionLabel);
  }

  updateView() {
    this.swfService.SearchWithExistingQuery()
  }

  clearAllFilters(){
    this.googleCategoryResultService.filterChipAction$.next(filterActionList.clearAll)
  }
}
