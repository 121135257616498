<div class="d-flex flex-row collection-breadcrumb l-container" *appDeviceType="'desktop'">
  <div class="breadcrumb-text" (click)="home()">Shop</div>

  <!-- single page -->
  <img src="../../../../assets/icons/right-arrow.svg" alt="" *ngIf="!isMultiLevel"/>
  <div class="breadcrumb-text" *ngIf="!isMultiLevel">{{page}}</div>


  <!-- multi page - query or route based -->
  <div class="breadcrumb-text" *ngFor="let label of labels; let i = index" (click)="navigate(i)">
    <img src="../../../../assets/icons/right-arrow.svg" alt="" />
    {{ label }}
  </div>

</div>
