<app-search-section></app-search-section>
<app-breadcrumb [page]="pageConstants.route" [multiLevel]="breadcrumbData"></app-breadcrumb>
<p-progressBar
  *ngIf="!(swfService.responseData.received || swfService.responseData.error)"
  mode="indeterminate"
  styleClass="container-laasy mb-4"
  [style]="{'height': '5px'}">
</p-progressBar>
<div *ngIf="swfService.responseData.received" class="search-result-parent-container l-container">
  <div class="md filters-section-container" *appDeviceType="'desktop'">
    <ng-template [ngTemplateOutlet]="filterSection"></ng-template>
  </div>
  <app-empty-state-display
    style="flex-grow: 2;"
    [searchQuery]="searchQuery"
    *ngIf="swfService.responseData.received && results.length === 0"
  >
  </app-empty-state-display>
  <div
    *ngIf="swfService.responseData.received && results.length !== 0"
    class="result-section">
    <div class="sm">
      <ng-template [ngTemplateOutlet]="resultCount"></ng-template>
    </div>

    <app-google-category-result-header
      #googleResultHeader
      [totalItm]="totalItm"
      [sortByitems]="sortByitems"
      [activeCategory]="activeCategory"
      (action)="resultHeader($event)"></app-google-category-result-header>

    <div class="results-container">
      <app-shop-product-card [items]="results" [isFlexwrap]="true" [isDataLoaded]="skeletonLoader_isDataLoaded"></app-shop-product-card>
    </div>
  </div>
</div>
<div class="search-result-parent-container l-container" appStylePagination>
  <div class="dummy-filter-pagination"></div>
  <div class="pagination" [ngClass]="{'node-visible':showPagination,'node-hidden':!showPagination}">
    <app-pagination [currentPageNumber]="currentPageNumber" [totalPages]="totalPages"
        (onPageChangeEvent)="getDataByPage($event)"></app-pagination>
  </div>
</div>

<ng-template #filterSection>
  <app-google-filters #filter appStyleFilter></app-google-filters>
</ng-template>

<ng-template #resultCount>
  <b class="result-label">{{activeCategory}}</b>
</ng-template>

<ng-template [ngTemplateOutlet]="filterSection" *appDeviceType="'mobile'"></ng-template>
