// Function to calculate the prefix sum array
export function calculatePrefixSum(arr: any) {
  const prefixSum = [0]; // Initialize with 0 to simplify sum calculation
  for (let i = 0; i < arr.length; i++) {
    prefixSum[i + 1] = prefixSum[i] + arr[i]; // Cumulative sum up to the current index
  }
  return prefixSum;
}

// Function to sum values between given indices using prefix sum
export function sumBetweenIndicesOptimized(arr: any, indices: any) {
  const prefixSum = calculatePrefixSum(arr);
  let sumRanges = [];

  // Loop through the indices array and sum the values between them
  for (let i = 0; i < indices.length - 1; i++) {
    const startIndex = indices[i];
    const endIndex = indices[i + 1];

    // Sum using prefix sum array: sum from startIndex to endIndex-1
    const rangeSum = prefixSum[endIndex] - prefixSum[startIndex];
    sumRanges.push(rangeSum);
  }

  return sumRanges;
}

export function findGroupsWithSumLessThanX(arr: any, x: any) {
  // Step 1: Create an array to hold the indices of each group
  let groups = [];

  // Step 2: Sort the array with their indices to ensure we can track original positions
  let indexedArr = arr.map((value: any, index: any) => ({ value, index }));
  //indexedArr.sort((a:any, b:any) => a.value - b.value);  // Sort by value (ascending)

  // Step 3: Iterate over the sorted array and create groups
  let currentGroup: any = [];
  let currentSum = 0;

  for (let i = 0; i < indexedArr.length; i++) {
    const element = indexedArr[i];

    // If adding this element doesn't exceed the sum x, add it to the current group
    if (currentSum + element.value <= x) {
      currentGroup.push(element.index);  // Store the original index
      currentSum += element.value;
    } else {
      // If sum exceeds, push the current group and start a new group
      if (currentGroup.length > 0) {
        groups.push(currentGroup);
      }
      currentGroup = [element.index];  // Start a new group with the current element
      currentSum = element.value;  // Update the sum with the new group element
    }
  }

  // Push the last group if it has elements
  if (currentGroup.length > 0) {
    groups.push(currentGroup);
  }

  return groups;
}


export function findCategoryById(categories: any[], menuItemId: string): any {
  type Ancestor = { menuItemId: any; label: any; level: any };
  const stack = categories.map(category => ({ category, ancestors: [] as Ancestor[] }));

  while (stack.length) {
    const { category, ancestors } = stack.pop()!;
    if (category.menuItemId === menuItemId) {
      return { category, ancestors };
    }
    if (category.children) {
      for (const child of category.children) {
        stack.push({ category: child, ancestors: [...ancestors, { menuItemId: category.menuItemId, label: category.label, level: category.level }] });
      }
    }
  }

  return null;
}
