import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
   name: 'searchFilter',
   standalone: true,
})
export class SearchFilterPipe implements PipeTransform {
  transform(filters: any, searchValue: string) {
      if(searchValue) {
        return filters.options.filter((option :any) => {
          return option.label.toLowerCase().includes(searchValue.toLowerCase());
        })
      } else {
          return filters.options;
      }
  }
}
