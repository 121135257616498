import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FormsModule } from '@angular/forms';
import { SearchCacheKey } from '../../utilities/constants';
import { SearchService } from '../../services/search/search.service';
import { Subscription } from 'rxjs';
import { SearchWithFilterService } from '../../services/search/search-with-filter.service';
import { TypeOfSearch } from '@laasy/common-layout/shop';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [CommonModule, AutoCompleteModule, FormsModule],
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

  querySearch = ''
  results: string[] = []
  prevResult: string[] = []
  isRecentQuery = true
  isFullscreen = false;
  subsList !: Subscription[];
  overlayClass = 'd-block';
  searchType: string = TypeOfSearch.TypedText

  constructor(public readonly swfService: SearchWithFilterService, private searchService: SearchService,) {
    this.results = this.getPrevSearch()
  }

  ngOnInit(): void {
    this.searchService.getQuery().subscribe({
      next: (query) => this.querySearch = query
    })
  }

  ngOnDestroy(): void {
    this.subsList?.forEach(sub => sub?.unsubscribe());
  }

  onInput(event: Event) {
    if (this.searchType != TypeOfSearch.PastedText)
      this.searchType = TypeOfSearch.TypedText
  }

  onPaste(event: ClipboardEvent) {
    this.searchType = TypeOfSearch.PastedText
  }

  onRecentSearchClick(){
    this.searchType = TypeOfSearch.ClickOnRecentSearches
  }
  search() {
    this.swfService.requestData.typeOfSearch = this.searchType
    this.searchType = TypeOfSearch.TypedText
    this.isRecentQuery = true
    if (!this.querySearch) {
      this.results = [...this.prevResult]
    }
    else {
      this.storePrevSearch(this.querySearch)
      this.turnOffFullScreen();
    }
    this.searchService.onSearch.next(this.querySearch);
  }

  removeCachedSuggestion(suggestion: string) {
    const recentSearch = this.getPrevSearch().filter((item: string) => item !== suggestion)
    localStorage.setItem(SearchCacheKey, JSON.stringify(recentSearch))
    this.prevResult = recentSearch
    this.results = recentSearch
  }

  onSearchKeyUp(): void {
    if (this.querySearch === ''){
      this.results = this.getPrevSearch();
      this.isRecentQuery = true;
      this.overlayClass = 'd-block'
    }
    else{
      // This block will modify while implementing search suggestions
      this.results = [];
      this.isRecentQuery = false;
      this.overlayClass = 'd-none';
    }
  }

  onSearchFocus(): void {
    if (this.querySearch === ''){
      this.results = this.getPrevSearch();
    }
    this.isFullscreen = window.innerWidth < 600;
  }

  storePrevSearch(query: string) {
    let recentSearch = this.getPrevSearch()
    if (recentSearch.includes(query)) return
    if (recentSearch.length > 3) recentSearch.pop()
    recentSearch.unshift(query)
    localStorage.setItem(SearchCacheKey, JSON.stringify(recentSearch))
  }

  getPrevSearch() {
    this.prevResult = JSON.parse(localStorage.getItem(SearchCacheKey) || '[]')
    return this.prevResult
  }


  @HostListener('window:popstate', ['$event'])
  turnOffFullScreen(){
    setTimeout(() => this.isFullscreen = false,10);
    const input = document.querySelector('.search-input-box input') as HTMLInputElement;
    input.blur();
  }
}
