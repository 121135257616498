import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchSectionComponent } from '../../shared/components/search-section/search-section.component';
import { BannerCardComponent } from './banner-card/banner-card.component';
import { ShopByCategoryDirective } from '../../shared/directives/shop-by-category.directive';
import { LandingService } from 'src/app/shared/services/Page/landing.service';
import { Banner, RoundCard } from 'src/app/shared/models/landing';
import { BehaviorSubject, first, forkJoin, Observable, Subscription } from 'rxjs';
import { CollectionsComponent } from './collections-section/collections-section.component';
import { Router } from '@angular/router';
import { SearchSectionService } from 'src/app/shared/services/search/search-section.service';
import { MapCardCollectionPipe } from 'src/app/shared/pipes/map-card-collection.pipe';
import { MapCollectionSectionSkusPipe } from 'src/app/shared/pipes/map-collection-section-skus.pipe';
import { SearchService } from 'src/app/shared/services/search/search.service';
import { collectionResultPage, collectionType, SearchConst, skeleton_data_landing } from 'src/app/shared/utilities/constants';
import { SearchWithFilterService } from 'src/app/shared/services/search/search-with-filter.service';
import { CircularLogoLabelComponent, ShopModule, TypeOfSearch } from '@laasy/common-layout/shop';
import { CommonComponentModule } from '@laasy/common-layout/common-components';
import { SkeletonModule } from 'primeng/skeleton';
import { CommonService, DeviceTypeDirective, sortItems } from '@laasy/common-layout';

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [CommonModule, CollectionsComponent, BannerCardComponent, CircularLogoLabelComponent, SearchSectionComponent, ShopByCategoryDirective, MapCollectionSectionSkusPipe, MapCardCollectionPipe, ShopModule, CommonComponentModule, SkeletonModule, DeviceTypeDirective],
  providers: [MapCardCollectionPipe, MapCollectionSectionSkusPipe],
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, OnDestroy {

  categoryData$ = new BehaviorSubject<RoundCard[]>([]);
  skeleton_data_landing: any[] = skeleton_data_landing
  topBrands$: Observable<any[]> | undefined;
  topRetailers$: Observable<any[]> | undefined;
  bannerData: Banner[] = []
  showBestSellers = false;
  showHighestCashback = false;
  subs: Subscription[] = [];
  components = {
    heroBanner: 'heroBanner',
    topBrands: 'topBrands',
    shopByCategory: 'shopByCategory',
    skeletonCollectionCarousel: 'skeleton collectionCarousel',
    communityStats: 'community stats',
    collectionCarousel: 'collectionCarousel',
    collectionCard: 'collectionCard',
  }
  layout = [
    { name: this.components.heroBanner, rank: -10, dataLoaded: false },
    { name: this.components.topBrands, rank: -9 },
    { name: this.components.shopByCategory, rank: -8 },
    { name: this.components.skeletonCollectionCarousel, rank: 2, data: { skus: skeleton_data_landing[0].skus, bannerTitle: skeleton_data_landing[0].bannerTitle, collectionName:'' }, dataLoaded: false },
    { name: this.components.skeletonCollectionCarousel, rank: 3, data: { skus: skeleton_data_landing[0].skus, bannerTitle: '',collectionName:'' }, dataLoaded: false },
    { name: this.components.communityStats, rank: 1000 },
  ]

  constructor(
    private landingService: LandingService,
    private commonService: CommonService,
    private router: Router,
    public searchSecService: SearchSectionService,
    private searchService: SearchService,
    public readonly swfService: SearchWithFilterService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    localStorage.removeItem('search-query');
    this.searchSecService.shopCategories$.subscribe({
      next: (data) => {
        this.categoryData$.next(data.list)
      }
    });
    forkJoin([this.landingService.getCollections(collectionType.nonUser).pipe(first()), this.landingService.getCollections(collectionType.user).pipe(first()), this.commonService.getUserPref().pipe(first())]).subscribe(data => {
      const [nonUserCollections, userCollections, userPrefs] = structuredClone(data)
      const userPrefCollections = userCollections.filter((x: any) => {
        return userPrefs.includes(x.systemName) && x.level === collectionType.user;
      });
      const systemCollections = this.checkSearchSectionCollection(nonUserCollections).filter((item: any) => item.level === collectionType.system)
      const nonSystemCollections = this.checkSearchSectionCollection(nonUserCollections).filter((item: any) => item.level !== collectionType.system)

      const sortednonSystemCollections = sortItems(nonSystemCollections, 'rank', false);
      const sortedSystemCollections = sortItems(systemCollections, 'rank', false);
      const sortedUserPrefCollections = sortItems(userPrefCollections, 'rank', false);

      const collections = sortedUserPrefCollections.concat(sortednonSystemCollections, sortedSystemCollections);
      collections.forEach((item: any, i: number) => item.rank = i);
      this.getBannerComponent()!.dataLoaded = true
      this.collectionsForLayout(collections)
    });

    this.topRetailers$ = this.landingService.getTopMerchants();
    this.topBrands$ = this.landingService.getTopBrands();
    this.subs = [
      this.searchService.onSearch.subscribe({
        next: (query) => this.navigateToResult(query)
      }),
      this.landingService.getBannerData().subscribe(data => {
        this.bannerData = data
      })
    ]
  }

  getBannerComponent() {
    return this.layout.find(item => item.name === this.components.heroBanner) ?? { name: this.components.heroBanner, rank: -1, dataLoaded: false }
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub?.unsubscribe());
  }

  collectionsForLayout(res: any) {
    const data = this.sortByRank(res);
    data.forEach((x: any) => {
      if (!x.isCard)
        this.layout.push({
          name: `${this.components.collectionCarousel} ${x.bannerTitle}`, rank: x.rank, data: {
            skus: x.skus, bannerTitle: x.bannerTitle , collectionName: x.collectionName
          }, dataLoaded: true
        })
      else {
        const sortedCardData = this.landingService.getSortedCollectionCards(x.cardList);
        this.layout.push({ name: `${this.components.collectionCard} old`, rank: x.rank, data: sortedCardData, dataLoaded: true })
      }
    })
    this.layout = this.layout.filter(item => item.name !== this.components.skeletonCollectionCarousel);
    this.layout = this.sortByRank(this.layout)
  }

  FeaturedSearchBy(e: any): void {
    let queryParams: any = { query: '', isFeatured: true }
    queryParams[e.type] = e.label
    let typeOfSearch = ''
    if (e.type == SearchConst.type.Brands) typeOfSearch = TypeOfSearch.ClickBrand
    else if (e.type == SearchConst.type.Merchants) typeOfSearch = TypeOfSearch.ClickRetailer
    if (typeOfSearch)
      this.swfService.requestData.typeOfSearch = typeOfSearch
    this.router.navigate(['search-result'], { queryParams: queryParams })
  }

  sortByRank(resp: any[]) {
    return resp.sort((row1, row2) => row1.rank > row2.rank ? 1 : -1);
  }

  checkSearchSectionCollection(res: any[]) {
    const hiCash = collectionResultPage['highestcashback'].route
    const bestSell = collectionResultPage['bestseller'].route
    const showLabel = {
      showHighestCashback: false,
      showBestSellers: false
    }
    if (res.find(item => item.collectionName === hiCash)) {
      showLabel.showHighestCashback = true;
    }
    if (res.find(item => item.collectionName === bestSell)) {
      showLabel.showBestSellers = true;
    }
    this.searchSecService.setLabelStatus(showLabel);
    return res.filter(item => !(
      item.collectionName === hiCash ||
      item.collectionName === bestSell
    ));
  }

  navigateToResult(query: string) {
    this.router.navigate(['search-result'], {
      queryParams: { query }
    });
  }
}
