import { Injectable } from '@angular/core';
import { categoryResultPage } from '../../utilities/constants';
import { multiLevelBreadCrumb } from '../../components/breadcrumb/model';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class GoogleCategoryResultService {

  constructor() { }

  filterItems$ = new BehaviorSubject<any[]>([]);
  filterChipAction$ = new BehaviorSubject<string>('');

  getBreadcrumb(queryParams: any) {
    let  breadcrumbData:multiLevelBreadCrumb[] = []
    const queryParamKeys = categoryResultPage.queryParams.slice(0, -1)
    Object.keys(queryParams).forEach((key: string) => {
      const value = queryParams[key]

      if (queryParamKeys.includes(key)) {
        breadcrumbData = [
          ...breadcrumbData,
          {
            label: value,
            route: value,
            queryName: key
          }
        ];
      }
    })
    return breadcrumbData
  }

}
