<div class="category-search-result-header-parent-container">
  <div class="result-header">
    <div class="search-result-left-container" *appDeviceType="'desktop'">
      <div class="md">
        <ng-template [ngTemplateOutlet]="resultCount"></ng-template>
      </div >
    </div>
    <div class="total-items" style="display: flex;" *appDeviceType="'desktop'">
      {{totalItm}} {{totalItm > 1? 'items':'item'}}
      <span class="vertical-sep-line"></span>
      <app-google-sort-by
        #sortBy [items]="sortByitems"
        (selected)="action.emit({type: 'updateSortBy', payload: $event})"
        (selectedString)="action.emit({type: 'setSortByString', payload: $event})">
      </app-google-sort-by>
    </div>

    <div class="search-result-left-container" *appDeviceType="'mobile'">
      <div class="md">
        <ng-template [ngTemplateOutlet]="resultCount"></ng-template>
      </div >
      <div class="sm" style="display: flex; flex-direction: row;">
        <div class="filterlabel-chip" (click)="action.emit(action.emit({type: 'openFilterSection'}))">
          <span class="filter-icon"><img src="../../../../assets/icons/filterIcon.svg" alt=""></span>
          <span class="filter-label">Filters</span>
        </div>
        <app-google-sort-by
          #sortBy [items]="sortByitems"
          (selected)="action.emit({type: 'updateSortBy', payload: $event})"
          (selectedString)="action.emit({type: 'setSortByString', payload: $event})">
        </app-google-sort-by>
      </div>
    </div>

    <div class="total-items" *appDeviceType="'mobile'">
      <span class="vertical-sep-line"></span>
      {{totalItm}} {{totalItm > 1? 'items':'item'}}
    </div>

  </div>
  <div class="mb-1">
    <app-filter-chips></app-filter-chips>
  </div>
</div>


<ng-template #resultCount>
  <b class="result-label">{{activeCategory}}</b>
</ng-template>

