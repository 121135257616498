import { Injectable } from '@angular/core';
import { SearchRequest, SearchResponse } from '../../models/Search';
import { BehaviorSubject, distinctUntilChanged, filter, retry } from 'rxjs';
import { searchApi } from '../../utilities/apiConstants';
import { AuthorizedApiService, getCachedTenantData } from '@laasy/common-layout';
import { environment } from 'src/environments/environment';
import { NotificationService } from '../common/notification.service';
import { categoryResultPage, searchDefaultSort } from '../../utilities/constants';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SearchWithFilterService {

  requestData !: SearchRequest;
  responseData!: SearchResponse;
  onResult: BehaviorSubject<SearchResponse>;
  private requestData$ = new BehaviorSubject<string>('');

  constructor(
    private readonly authService:AuthorizedApiService,
    private readonly notification: NotificationService,
    private readonly route: ActivatedRoute
  ) {
    this.resetRequestData();
    this.resetResponseData()
    this.onResult = new BehaviorSubject(this.responseData);
    this.getrequestData$().subscribe(_ => this.triggerSearch());
  }

  getrequestData$(){
    return this.requestData$.pipe(distinctUntilChanged(),filter(data => !!data))
  }

  featuredSearch(featuredData:any){
    const keys = Object.keys(featuredData)
    keys.forEach((key:string) => {
      if(featuredData[key])
        this.requestData.filter[key] = featuredData[key]
    });
  }

  removeCategoryLevels(){
    categoryResultPage.queryParams.forEach((key:string) => {
      if(this.requestData.filter[key])
        delete this.requestData.filter[key]
    })
  }

  search(query: string){
    this.requestData.searchKeyword = query;
    this.requestData.contextText = query;
    this.requestData$.next(JSON.stringify(this.requestData));
  }

  triggerSearch(){
    this.responseData.received = false;
    this.responseData.error = false;
    this.authService.post<SearchResponse>(searchApi,this.requestData, ).pipe(retry(2)).subscribe({
      next: (res: SearchResponse) => {
        this.requestData$.next('');
        const aggregations = structuredClone(this.responseData.aggregations)
        this.responseData = res
        this.responseData.received = true;


        // if there is no skus then use prev filters
        const tenantData = getCachedTenantData()
        const isGoogleCat = tenantData?.hasGoogleCategorization
        if(res.data.length==0 && isGoogleCat) {
          if (aggregations?.filters?.Price?.[0]) {
            aggregations.filters.Price[0].item = this.requestData.filter.priceRange.lowest;
          }
          if (aggregations?.filters?.Price?.[1]) {
            aggregations.filters.Price[1].item = this.requestData.filter.priceRange.highest;
          }
          this.responseData.aggregations = aggregations
        }
        this.onResult.next({ ...this.responseData, currentPageNumber: this.requestData.pageNumber });
      },
      error: () => {
        this.responseData.error = true;
        this.showErrorToast()
      }
    })
  }

  resetPageNumber(){
    this.requestData.pageNumber = 1;
  }

  resetSorting(){
    this.requestData.sortBy = searchDefaultSort.value;
  }

  resetRequestData(){
    this.requestData = {
      contextText: "",
      filter: {
          Brands: [],
          cashbackRange: {
              highest: 0,
              lowest: 0
          },
          Categories: [],
          Colors: [],
          priceRange: {
              highest: 0,
              lowest: 0
          },
          Sizes: []
      },
      pageNumber: 1,
      pageSize: 20,
      searchKeyword: "",
      sortBy: searchDefaultSort.value,
      typeOfSearch: this.requestData?.typeOfSearch || ''
    }
  }

  setPriceRange(lowest: number, highest: number){
    this.requestData.filter.priceRange = {highest,lowest};
  }

  resetFilter(isGoogleCategorization = false){
    this.requestData.filter = {
      Brands: [],
      cashbackRange: {
          highest: 0,
          lowest: 0
      },
      Categories: [],
      Colors: [],
      priceRange: {
          highest: 0,
          lowest: 0
      },
      Sizes: []
    }
    if(isGoogleCategorization){
      const qParams = this.route.snapshot.queryParams;
      categoryResultPage.queryParams.slice(0,-1).forEach((key:string) => {
        if(qParams[key])
          this.requestData.filter[key] = [qParams[key]]
      })
    }
  }

  resetResponseData(){
    this.responseData = {
      aggregations: {
        filters: {}
      },
      data: [],
      totalCount: 0,
      received: false,
      error: false
    };
    if(this.onResult)
      this.onResult.next(this.responseData);
  }

  showErrorToast(){
    const title = 'Something went wrong';
    const msg = 'Please try again or contact support'
    this.notification.showError(title,msg);
  }

  resetPageAndSearch(searchQuery = ''){
    this.getDataByPage(1,searchQuery);
  }

  SearchWithExistingQuery(){
    const queryParams = this.route.snapshot.queryParams;
    const query = queryParams['query'] || '';
    this.getDataByPage(1,query);
  }

  getDataByPage( pageNumber: number, searchQuery:string) {
    this.requestData.pageNumber = pageNumber
    this.search(searchQuery)
  }

}
