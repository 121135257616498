import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncatePipe } from '../../pipes/truncate.pipe';
import { FilterChipsComponent } from '../google-filters/filter-chips/filter-chips.component';
import { SearchSectionService } from '../../services/search/search-section.service';

@Component({
  selector: 'app-empty-state-display',
  standalone: true,
  imports: [CommonModule, TruncatePipe,FilterChipsComponent],
  templateUrl: './empty-state-display.component.html',
  styleUrls: ['./empty-state-display.component.scss']
})
export class EmptyStateDisplayComponent {
  @Input() searchQuery !: string;

  constructor(public searchSection:SearchSectionService) { }
}
