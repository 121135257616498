import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, map, of, tap } from 'rxjs';
import { categories, googleCategories } from '../../utilities/apiConstants';
import { SearchWithFilterService } from './search-with-filter.service';
import { AuthorizedApiService, getCachedTenantData } from '@laasy/common-layout';
import { TypeOfSearch } from '@laasy/common-layout/shop';
import { SearchCategory } from '../../models/Search';
import { CategoryComp, categoryResultPage } from '../../utilities/constants';

interface LabelStatus {
  showHighestCashback: boolean,
  showBestSellers: boolean
};
@Injectable({
  providedIn: 'root'
})
export class SearchSectionService {
  private showlabelKey = 'searchSectionLabelShow'
  showLabel: BehaviorSubject<LabelStatus>;
  shopCategories$ = new BehaviorSubject<any>({
    list: [],
    dropDown: []
  });

  private router = inject(Router);

  constructor(public readonly swfService: SearchWithFilterService, private authService: AuthorizedApiService) {
    const labelStatus = this.getLabelStatus();
    this.showLabel = new BehaviorSubject(labelStatus);
  }

  getLabelStatus() {
    const status = localStorage.getItem(this.showlabelKey);
    if (status) {
      return JSON.parse(status)
    } else {
      return {
        showHighestCashback: false,
        showBestSellers: false
      };
    }
  }
  setLabelStatus(status: LabelStatus) {
    localStorage.setItem(this.showlabelKey, JSON.stringify(status));
    this.showLabel.next(status);
  }

  categoryClick({ label, source }: any) {
    const typeOfSearch = (source == TypeOfSearch.SourceDropdown) ? TypeOfSearch.ClickDropdownCategory : TypeOfSearch.ClickCategory
    this.swfService.resetRequestData();
    this.swfService.requestData.typeOfSearch = typeOfSearch

    if(this.isGoogleCategorization())
      this.router.navigate([categoryResultPage.route], {
        queryParams: { query: '', Categories: label }
      });
    else
      this.router.navigate(['search-result'], {
        queryParams: { query: '', isFeatured: 'true', Categories: label }
      });
  }

  getLevelOneCategories(isList?: boolean | false): Observable<SearchCategory[]> {
    return this.authService.getAndCacheWithLocal(`${categories}`, false, 24 * 60 * 60).pipe(
      map((res: any) => {
        const data = res.map((obj: any) => ({
          label: obj.name,
          image: obj.imageUrl,
          showInList: obj.showInList,
          showInDropdown: obj.showInDropdown
        }));
        return data;
      })
    );
  }

  getGoogleCategories() {
    return this.authService.getAndCacheWithLocal(googleCategories)
      .pipe(map((data: any) => {
        let menuItemId = 0
        data.forEach((l1: any) => {
          l1.label = l1.displayName ?? l1.name
          l1.menuItemId = menuItemId++
          if (l1.children.length)
            l1.children.forEach((l2: any) => {
              l2.label = l2.displayName ?? l2.name
              l2.menuItemId = menuItemId++
              l2.children.forEach((l3: any) => {
                l3.label = l3.displayName ?? l3.name
                l3.menuItemId = menuItemId++
              })
              l2.children.unshift({ children: [], label: CategoryComp.shopAll, route:l2.label, menuItemId:menuItemId++, level: 3 })
            })
        })
        return data
      }))
  }

  isGoogleCategorization() {
    const tenantData = getCachedTenantData()
    const isGoogleCat = tenantData?.hasGoogleCategorization
    // return true
    return isGoogleCat
  }

  getCategories() {
    const isGoogleCat = this.isGoogleCategorization()
    const levelOneCat$ = this.getLevelOneCategories()
    const googleCat$ = this.getGoogleCategories()
    const categories$ = (isGoogleCat) ? googleCat$ : levelOneCat$

    return categories$.pipe(tap((data: any) => {
      if (isGoogleCat){
        const categoryList:any[] = []
        data.forEach((x:any) => {
          categoryList.push({
            label: x.label,
            image: `/assets/images/category-logo/${x.name.replace(/\s/g, '')}GoogleTaxIcon.png`
          })
        });

        this.shopCategories$.next({
          list: categoryList,
          dropDown: data,
          isGoogleCat: isGoogleCat
        })
      }
      else
        this.shopCategories$.next({
          list: data.filter((x: any) => x.showInList),
          dropDown: data.filter((x: any) => x.showInDropdown),
          isGoogleCat: isGoogleCat
        })
    }))
  }

}
