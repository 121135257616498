import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceTypeDirective } from '@laasy/common-layout';
import { GoogleSortByComponent } from 'src/app/shared/components/google-sort-by/sort-by.component';
import { FilterChipsComponent } from 'src/app/shared/components/google-filters/filter-chips/filter-chips.component';

@Component({
  selector: 'app-google-category-result-header',
  standalone: true,
  imports: [CommonModule, DeviceTypeDirective, GoogleSortByComponent,FilterChipsComponent],
  templateUrl: './google-category-result-header.component.html',
  styleUrls: ['./google-category-result-header.component.scss']
})
export class GoogleCategoryResultHeaderComponent {

  @Input() totalItm: number = 0;
  @Input() activeCategory: string = '';
  @Input() sortByitems!: any;
  @Output() action: EventEmitter<any> = new EventEmitter();
  @ViewChild('sortBy') sortBy !: GoogleSortByComponent;

}
