import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { DeviceTypeDirective } from '@laasy/common-layout';
import { multiLevelBreadCrumb } from './model';

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [CommonModule, DeviceTypeDirective],
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {

  routes: string[] = []
  labels: string[] = []
  queryParam: string[] = []
  isMultiLevel:boolean = false
  @Input() page: string = ''
  @Input()
  set multiLevel(routes: multiLevelBreadCrumb[]) {
    this.routes = routes.map((x: any) => x.route)
    this.labels = routes.map((x: any) => x.label)
    this.queryParam = routes.map((x: any) => x?.queryName)
    this.isMultiLevel = true
  }


  constructor(private router: Router) { }

  home() {
    this.router.navigateByUrl('/')
  }

  navigate(i: number) {
    if(i === this.labels.length-1) return
    // route based
    if (!this.queryParam.length)
      this.router.navigate(this.routes.slice(0, i + 1))

    // query param based
    else {
      const queryParams: any = {}
      this.queryParam.slice(0, i + 1).forEach((element: any, index: number) => {
        queryParams[this.queryParam[index]] = this.routes[index];
      });
      this.router.navigate([this.page], { queryParams })
    }
  }
}
