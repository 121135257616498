import { Pipe, PipeTransform } from '@angular/core';
import { filterOptionsToShow } from '../utilities/constants';

@Pipe({
  name: 'seeMoreOptions',
  standalone: true,
  pure: false
})

export class SeeMoreOptionsPipe implements PipeTransform {
  transform(filters: any, maxLimit: number) {
    if(filters) {
      return filters.slice(0, maxLimit);
    } else {
      return [];
    }
  }
}
