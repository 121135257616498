<div class="filters-section">
  <div class="search-result-header">
    <span class="title">Filters</span>
    <div class="d-flex gap-2 align-items-center">
      <span *ngIf="showClear" class="clear-all-btn" (click)="clearAllFilters()">Clear all</span>
      <svg (click)="bSheet.close()" class="sm" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L12 10.9393L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L13.0607 12L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L12 13.0607L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L10.9393 12L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z" fill="#222222"/>
      </svg>
    </div>
  </div>
  <div class="chip-wrapper">
    <div *ngFor="let chip of filterChips; let i = index" class="filter-chip"  [attr.data-testchip]="chip.label">
      <span class="filter-text-format">{{chip.label}}</span>
      <ng-template [ngTemplateOutlet]="cross" [ngTemplateOutletContext]="{index:i}"></ng-template>
    </div>
  </div>
  <div class="filter-group-container">
    <ng-template [ngTemplateOutlet]="filter" *ngFor="let filterObj of filterItems"
      [ngTemplateOutletContext]="{filterObj:filterObj}"></ng-template>
  </div>
  <ng-template #filter let-filterObj="filterObj">
    <div class="filter-group">
      <div class="filter-selection-container" (click)="toggleFilterOptions( filterObj)">
        <div class="filter-selection">{{filterObj.label}}</div>
        <img class="downarrow-alignment" src="../../../assets/icons/downarrow.svg" alt="">
      </div>

      <ng-container *ngIf="filterObj.isOpen && filterObj.multiSelect">
        <div class="filter-options" *ngFor="let option of filterObj.options">
          <p-checkbox
            [(ngModel)]="option.isSelected"
            [binary]="true"
            (onChange)="
              onFilterCheckboxClick( option.label,filterObj.label,option.isSelected);
              updateRequestFilter(filterObj.label, option.label,option.isSelected)
            ">
          </p-checkbox>
          <p>{{option.label}}</p>
        </div>
      </ng-container>
      <ng-container *ngIf="filterObj.isOpen && !filterObj.multiSelect">
        <div class="filter-options" *ngFor="let item of filterObj.options">
          <p-radioButton [name]="filterObj.label" [value]="item.label" [(ngModel)]="filterObj.selectedValue" [inputId]="item.label"></p-radioButton>
            <label [for]="item.label" class="ml-2">{{item.label}}</label>
        </div>
      </ng-container>
      <ng-container *ngIf="filterObj.isOpen && filterObj.label === 'Price'">
        <div class="filter-price-slider">
          <p-slider
          [(ngModel)]="priceRange"
          [range]="true"
          [min]="minPrice"
          [max]="maxPrice"
          (onSlideEnd)="onPriceChange($event)"
        ></p-slider>
        </div>
        <div class="d-flex justify-content-between">
          <div class="price-box">
            <span [ngClass]="{black: priceRange[0]!==minPrice}">$</span>
            <input
              type="number"
              [min]="minPrice"
              [max]="maxPrice"
              [ngModel]="priceRange[0]"
              (ngModelChange)="setPriceRange$.next({price: $event,type: 'min'})"
              [ngClass]="{black: priceRange[0]!==minPrice}"
            />
          </div>
          <div class="price-box">
            <span [ngClass]="{black: priceRange[1]!==maxPrice}">$</span>
            <input
              type="number"
              [min]="minPrice"
              [max]="maxPrice"
              [ngModel]="priceRange[1]"
              (ngModelChange)="setPriceRange$.next({price: $event,type: 'max'})"
              [ngClass]="{black: priceRange[1]!==maxPrice}"
            />
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>

<ng-template #cross let-i="index">
  <svg (click)="removeFilterByChip(i)" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.44309 1.44602C1.56513 1.32398 1.76299 1.32398 1.88503 1.44602L4.9974 4.55838L8.10976 1.44602C8.2318 1.32398 8.42966 1.32398 8.5517 1.44602C8.67374 1.56806 8.67374 1.76592 8.5517 1.88796L5.43934 5.00033L8.5517 8.11269C8.67374 8.23473 8.67374 8.43259 8.5517 8.55463C8.42966 8.67667 8.2318 8.67667 8.10976 8.55463L4.9974 5.44227L1.88503 8.55463C1.76299 8.67667 1.56513 8.67667 1.44309 8.55463C1.32105 8.43259 1.32105 8.23473 1.44309 8.11269L4.55545 5.00033L1.44309 1.88796C1.32105 1.76592 1.32105 1.56806 1.44309 1.44602Z" fill="#222222"/>
  </svg>
</ng-template>
