import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { SearchWithFilterService } from '../../services/search/search-with-filter.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { BehaviorSubject, debounceTime, Subject, Subscription } from 'rxjs';
import { SliderModule } from 'primeng/slider';
import { SearchConst, searchDefaultSort } from '../../utilities/constants';
import { LandingService } from '../../services/Page/landing.service';
import { SearchSelectedFilterService } from '../../services/search/search-selected-filter.service';
import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';
import { getPriceRange } from '../../utilities/helpers';
import { SortByComponent } from '../sort-by/sort-by.component';
import { FilterItem, SearchResponse } from '../../models/Search';
import { SearchService } from '../../services/search/search.service';

@Component({
  selector: 'app-filters',
  standalone: true,
  imports: [CommonModule, CheckboxModule, RadioButtonModule, FormsModule, SliderModule],
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, OnDestroy {
  @Output() pageNumberChange = new EventEmitter<any>();
  filterChips : {
    label: string,
    filterName: string
  }[] = [];
  showClear: boolean = false;
  showClear$ = new BehaviorSubject<boolean>(false);
  currentPageNumber = 1
  searchQuery = ''
  showPagination = false
  filterItems !: FilterItem[];
  isFeatured = false;
  turningFeaturedOff = false;
  priceRange: number[] = [];
  minPrice = 0;
  maxPrice !: number;
  setPriceRange$ = new Subject<{
    price: number | null,
    type: 'min' | 'max'
  }>();
  @Input() searchResponse!: SearchResponse;
  featured_logo=''
  featured_label= ''
  featured_type=''
  sortValue = searchDefaultSort;
  pageSize: number = 20;
  subsList !: Subscription[];


  @ViewChild('bSheet') bSheet !: BottomSheetComponent;
  @ViewChild('sortBy') sortBy !: SortByComponent;


  constructor(
    private swfService: SearchWithFilterService,
    private router: Router,
    private route: ActivatedRoute,
    private landingService: LandingService,
    private ssfService: SearchSelectedFilterService,
    private cdRef: ChangeDetectorRef,
    private searchService: SearchService
  ){}

  ngOnInit(): void {
    this.subsList = [
      this.route.queryParams.subscribe({
        next: (qParams) => this.onParamsChange(qParams['query'])
      }),
      this.ssfService.filterChips$.subscribe((data) => {
        this.filterChips = data;
      }),
      this.ssfService.showClear$.subscribe( (data) => {
        this.showClear = data;
      }),
      this.ssfService.getFilterList$().subscribe((data) => {
        this.setFilterList(data);
      }),
      this.setPriceRange$.pipe(debounceTime(600))
      .subscribe({
        next: ({price,type}) => this.setPriceRange(price,type)
      })
    ]
  }

  setFilterList(list: any){
    const exception = ['Cashbacks','Price','Colors', 'Sizes'];
    let filterItems: FilterItem[] = [];
    Object.keys(list).forEach((key) => {
      if(!exception.includes(key)){
        filterItems.push({
          label: key,
          options: list[key].map((obj: {count: number, item: string}) =>({
            label: obj.item,
            isSelected: this.swfService.requestData.filter[key]?.includes(obj.item),
            count: obj.count
          })),
          isOpen: false,
          showClear: list[key].some((obj: {item: string}) => {
            return this.swfService.requestData.filter[key]?.includes(obj.item)
          }),
          multiSelect: true,
          isSelected: false
        })
      }
    });

    if(list['Price']?.length){
      filterItems.push({
        label: 'Price',
        options: [],
        isOpen: false,
        showClear: this.filterChips?.some(
          chip => chip.filterName === 'Price'
        ),
        multiSelect: false,
        isSelected: false
      })

      if(this.priceRange.length === 0){
        this.priceRange = getPriceRange(list['Price']);
        [this.minPrice,this.maxPrice] = this.priceRange;
      }
    }
    this.filterItems = filterItems;
    this.filterItems.forEach((item:any) => {
        item.options = this.sortFilterOptions(item.options);
    });

  }

  toggleFilterOptions(filterObj: any) {
    const category = this.findItem(filterObj.label, this.filterItems)
    if (category[0]) {
      filterObj.isSelected = !filterObj.isSelected
      category[0].isOpen = !category[0].isOpen
      category[0].showClear = category[0].options.some((itm: any) => itm.isSelected)
    }
  }

  findItem(filterName: string, lookupArray: any) {
    return lookupArray.filter((itm: any) => itm.label == filterName)
  }

  onFilterCheckboxClick(label: string, filterName: string, isChecked: boolean): void {
    this.turnOffIsFeatured();
    const category = this.filterItems.find(
      filter => filter.label === filterName
    );
    if(category){
      if(isChecked) {
        category.showClear = true;
        this.ssfService.addSelectedFiltersData(label, filterName);
      } else {
        category.showClear = category.options?.some((option: any) => option.isSelected);
        this.filterChips = this.filterChips.filter((chip:any) => chip.label !== label);
      }
    }
    this.ssfService.showClearButton(this.filterChips.length > 1)
    this.pageNumberChange.emit(1);
  }

  onParamsChange(query: string){
    if(this.turningFeaturedOff){
      this.turningFeaturedOff = false;
      return;
    }
    this.filterChips = [];
    this.isFeatured = this.route.snapshot.queryParams['isFeatured'] ===  "true";
    if(this.isFeatured){
      this.executeFeaturedMode();
    }else {
      if(this.searchQuery != '' && this.searchQuery !== query){
        this.ssfService.updateSelectedFiltersData([]);
        this.ssfService.showClearButton(false);
        this.executeNormalMode();
      }
    }
    this.searchQuery = query;
    this.cdRef.detectChanges();
  }


  updateRequestFilter(key: string, value: string,selected: boolean){
    let filter = this.swfService.requestData.filter;
    if(!filter[key]){
      filter[key] = []
    }
    if(selected)
      filter[key].push(value);
    else{
      filter[key] = filter[key].filter((item: string) => item !== value);
    }
    this.pageNumberChange.emit(1);
  }

  turnOffIsFeatured(){
    if(this.isFeatured){
      this.isFeatured = false
      this.turningFeaturedOff = true;
      this.router.navigate(['search-result'],{
        queryParams: {query: this.searchQuery}
      })
    }else{
      this.turningFeaturedOff = false;
    }
  }

  onPriceChange(event: any){
    const Price = 'Price'
    const { values: [val1,val2] } = event;
    const min = Math.min(val1,val2);
    const max = Math.max(val1,val2);

    const initialCondition = min === this.minPrice && max === this.maxPrice;
    if(initialCondition){
      this.resetPrice();
    }else{
      this.swfService.setPriceRange(min, max);
    }
    this.pageNumberChange.emit(1);

    this.filterChips = this.filterChips.filter(
      (chip:any) => chip.filterName !== Price
    )

    const category = this.filterItems.find(
      filter => filter.label === Price
    );

    if(initialCondition){
      if(category) category.showClear = false;
      if(this.filterChips.length === 0) this.ssfService.showClearButton(false);
    }else{
      this.ssfService.addSelectedFiltersData(`$${min} - $${max}`,Price)
      if(category) category.showClear = true;
      this.ssfService.showClearButton(true);
    }
  }

  removeFilterByChip(i: number){
    const {filterName, label : optionLabel} = this.filterChips[i];
    const category = this.filterItems?.find(filter => filter.label === filterName)
    if (category){
      const option = category.options.find(
        opt => opt.label === optionLabel
      );
      if(option) option.isSelected = false;
      const reqFilter = this.swfService.requestData.filter;
      if(category.multiSelect){
        category.showClear = category.options?.some(opt => opt.isSelected)
        this.swfService.requestData.filter[filterName] = reqFilter[filterName]?.filter((item:string) => item !== optionLabel);
      }
      else{
        category.showClear = false;
        if(filterName === 'Price') this.resetPrice();
      }

    }
    this.filterChips = this.filterChips.filter((_,idx)=> idx!==i)
    this.ssfService.updateSelectedFiltersData(this.filterChips)
    if(this.filterChips.length > 1)
      this.ssfService.showClearButton(true)
    else
      this.ssfService.showClearButton(false)
    if(filterName !== 'Price') this.turnOffIsFeatured();
    this.pageNumberChange.emit(1);
    this.cdRef.detectChanges();
  }

  initFeatured(){
    if(this.route.snapshot.queryParams['isFeatured'] == "true") this.isFeatured = true
    else return
    const brand = this.route.snapshot.queryParams[SearchConst.type.Brands]
    const merchant = this.route.snapshot.queryParams[SearchConst.type.Merchants]
    const categories = this.route.snapshot.queryParams[SearchConst.type.Categories];
    let result = {}

    if(brand){
      this.featured_type = SearchConst.type.Brands
      result = {...result,Brands:[brand]}
      this.landingService.getTopBrands().subscribe((data)=>this.updateFeaturedData(data,brand))
    }
    if(merchant){
      this.featured_type = SearchConst.type.Merchants
      result = {...result,Merchants:[merchant]}
      this.landingService.getTopMerchants().subscribe((data)=>this.updateFeaturedData(data,merchant))
    }
    if(categories){
      this.featured_type = SearchConst.type.Categories
      result = {...result,Categories:[categories]}
      const data = [{
        label: categories,
        image: null
      }];
      this.updateFeaturedData(data,categories)
    }
    return result
  }

  updateFeaturedData(data:any, name:string){
    const featured_ = data.find((obj:any) => obj.label === name)
    if(!featured_) this.router.navigateByUrl('/')
    this.featured_logo=featured_.image
    this.featured_label =featured_.label
    this.ssfService.addSelectedFiltersData(this.featured_label, this.featured_type);
  }

  resetPrice(){
    this.priceRange = [this.minPrice,this.maxPrice];
    this.swfService.setPriceRange(0,0);
  }

  executeNormalMode(){
    this.showClear = false;
    this.swfService.resetRequestData();
  }

  executeFeaturedMode(){
    this.swfService.resetFilter();
    const featuredObj = this.initFeatured()
    this.swfService.featuredSearch(featuredObj)
  }

  sortFilterOptions(array: any) {
    const sortedData = array.sort((a:any, b:any) =>
      a.label.localeCompare(b.label)
    );
    return sortedData;
  }

  openFilterSection() {
    this.bSheet.open();
  }

  clearAllFilters(){
    this.turnOffIsFeatured();
    for(let fliterObj of this.filterItems){
      fliterObj.showClear = false;
      for(let opt of fliterObj.options){
        opt.isSelected = false;
      }
    }
    this.filterChips = [];
    this.ssfService.showClearButton(false);
    this.swfService.resetFilter();
    this.swfService.resetPageNumber();
    this.ssfService.updateSelectedFiltersData([]);
    this.resetPrice();
    this.currentPageNumber = 1;
    this.swfService.search(this.searchQuery);
  }

  setPriceRange(price: number | null, type: 'min' | 'max'){
    if(price === null) return;

    const [val1, val2] = this.priceRange;
    const min = Math.min(val1,val2);
    const max = Math.max(val1,val2);

    if(price < this.minPrice){
      price =  this.minPrice;
    }
    if(price > this.maxPrice){
      price =  this.maxPrice;
    }
    switch (type) {
      case 'min':
        if(price > max) price = max;
        this.priceRange = [price, max];
      break;
      case 'max':
        if(price < min) price = min;
          this.priceRange = [min,price];
      break;
    }
    this.onPriceChange({values: this.priceRange});
  }

  ngOnDestroy(): void {
    this.subsList.forEach(sub => sub?.unsubscribe());
  }
}
